import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters('matchStatus', ["_matchStatus"]),
    },
    methods: {
        matching_status(id) {
            let status = this.matching(id);
            return status.status;
        },
        matching_status_color(id) {
            let status = this.matching(id);
            return status.status_color;
        },
        matching(id) {
            let item = this._matchStatus.find(item => item.id == id)
            if (!item) return {status: 'belirsiz', status_color: 'secondary'}
            return item
        }
    }
}
